import React, { useState, useEffect } from 'react';
import { newRoom, joinRoom } from "./network.js";
import styles from './Tea.module.css';

const gameTypes = {
	clover: {
		name: "Clover",
		description: "Find words that match a three-letter sequence",
		icon: "./img/clover_small_still.png",
		flavor: "black"
	}, 
	daisy: {
		name: "Daisy",
		description: "Make words from a shared letter pool",

	}
}

const newSoloGame = (gameType, refresh) => {
	if ("flavor" in gameType) {
		newRoom({
			name: gameType.name,
			flavor: gameType.flavor,
			capacity: 1
		}).then((room) => {
			console.log("Created new solo game");
			refresh();
		});
	}
};

const newMultiGame = (gameType, numPlayers, refresh) => {
	if ("flavor" in gameType) {
		newRoom({
			name: gameType.name,
			flavor: gameType.flavor,
			capacity: numPlayers
		}).then((room) => {
			console.log("Created multiplayer game");
			refresh();
		});
	}
}

const joinGame = (roomID, refresh) => {
	joinRoom(roomID).then(() => {
		console.log("Joined room");
		refresh();
	});
}

export function GameSelect(props) {
	const [numPlayers, setNumPlayers] = useState(2);
	return (
		<div id={styles.gameselect}>
			{Object.keys(gameTypes).map((gameTypeName) => {
				let gameType = gameTypes[gameTypeName];
				if ("flavor" in gameType) {
					return (
						<div key={gameTypeName} className={styles.gameselect_gametype} >
							<div className={styles.gameselect_gametype_name}>
								{gameType.name}
							</div>
							<div className={styles.gameselect_gametype_description}>
								{gameType.description}
							</div>
							<div className={styles.gameselect_gametype_playsolo + " " + styles.gameselect_gametype_section}>
								<div className={styles.gameselect_gametype_button} onClick={() => { newSoloGame(gameType, props.refresh) }}>
									Play solo
								</div>
							</div>
							<div className={styles.gameselect_gametype_playmulti + " " + styles.gameselect_gametype_section}>
								<div className={styles.gameselect_gametype_button} onClick={() => { newMultiGame(gameType, numPlayers, props.refresh)}}>
									Create multiplayer game
								</div>
								<div className={styles.gameselect_gametype_numplayers}>
									{
										[2,3,4].map((num) => {
											if (numPlayers === num) {
												return (
													<div key={num} className={styles.gameselect_gametype_numplayers_num + " " + styles.gameselect_gametype_numplayers_num_selected}>
														{num} players
													</div>
												);
											} else {
												return (
													<div key={num} className={styles.gameselect_gametype_numplayers_num + " " + styles.gameselect_gametype_numplayers_num_unselected}
														onClick={() => { setNumPlayers(num) }}>
														{num} players
													</div>
												);
											}
										})
									}
								</div>
							</div>
							<div className={styles.gameselect_gametype_opengames}>
								<div className={styles.gameselect_gametype_opengames_heading}>
									{Object.keys(props.rooms).filter(roomID => props.rooms[roomID].flavor === gameType.flavor).length === 0 ? "No ongoing games" : "Ongoing games"}
								</div>
								<div className={styles.gameselect_gametype_opengames_gamelist}>
								
									{props.rooms.length === 0 ? "No open games to join" : Object.keys(props.rooms).filter((roomID) => {
										return props.rooms[roomID].flavor === gameType.flavor;
									}).map((roomID) => {
										return (
											<div key={roomID} className={styles.gameselect_gametype_opengames_gamelist_game}>
												<div className={styles.gameselect_gametype_opengames_gamelist_game_name}>
													{props.rooms[roomID].name}
												</div>
												<div className={styles.gameselect_gametype_opengames_gamelist_game_players}>
													{props.rooms[roomID].users.length}/{props.rooms[roomID].capacity} players
												</div>
												<div className={styles.gameselect_gametype_opengames_gamelist_game_join} onClick={() => { joinGame(roomID, props.refresh) }}>
													Join
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					);
				} else {
					return (
						<div key={gameTypeName} className={styles.gameselect_gametype} >
							<div className={styles.gameselect_gametype_name}>
								{gameType.name}
							</div>
							<div className={styles.gameselect_gametype_description}>
								{gameType.description}
							</div>
							<div>
								Coming soon!
							</div>
						</div>
					);
				}
			})}
		</div>
	);
}