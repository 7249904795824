import React from 'react';
import { Tea } from './features/tea/Tea';
import './App.css';

function App() {
  return (
    <div className="App">
		<Tea/>
    </div>
  );
}

export default App;
