import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './Tea.module.css';

export function TextBox(props) {
	let defaultValue = "";
	if ("default" in props) {
		defaultValue = props.default;
	}
	const [value, setValue] = useState(defaultValue);
	const input = useRef(null);

	useEffect(() => {
		if (props.important) {
			input.current.focus();
			input.current.select();
		}
	}, [props.important]);

	let onChange = (e) => {
		setValue(e.target.value);
	};

	let onLeave = (e) => {
		props.onLeave(value);
	}

	let handleKeyPress = (e) => {
		if (e.key === "Enter") {
			if ("onPressEnter" in props) {
				props.onPressEnter(value);
			}
		}
	}

	let password = "isPassword" in props && props.isPassword;

	if (password) {
		return (
			<input type="password" spellCheck="false" ref={input} value={value} onChange={onChange} onKeyPress={handleKeyPress} onBlur={onLeave}/>
		);
	} else {
		return (
			<input type="text" spellCheck="false" ref={input} value={value} onChange={onChange} onKeyPress={handleKeyPress} onBlur={onLeave}/>
		);
	}
}
