import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './Tea.module.css';

export function Modal(props) {

	return (
		<div id={styles.modal_structure}>
			<div id={styles.modal_backdrop} onClick={props.onCancel}>
				
			</div>
			<div className={styles.modal}>
				{props.content}
			</div>
		</div>
	);
}
