import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from "./Modal";
import { TextBox } from "./TextBox";
import { register } from "./network";
import { SHA256, SHA384 } from "sha2";
import styles from './Tea.module.css';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export function RegisterModal(props) {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [errorMessage, setErrorMessage] = useState("");

	let submit = () => {
		if (password === confirmPassword) {
			let saltedPassword = SHA256(password + email).toString("base64");
			register(email, saltedPassword).then((response) => {
				if (response.success) {
					props.onCancel();
					cookies.set("password", saltedPassword);
				} else {
					setErrorMessage(response.message);
				}
			});
		} else {
			setErrorMessage("Password fields do not match.");
		}
	};

	let errorMessageJSX = []
	if (errorMessage !== "") {
		errorMessageJSX = (
			<div id={styles.register_error}>
				{errorMessage}
			</div>
		);
	}

	let contentJSX = (
		<div id={styles.register}>
			<div>
				Email
			</div>
			<div>
				<TextBox
					onLeave={(v) => setEmail(v)}
					important={true}
				/>
			</div>
			<div>
				Password
			</div>
			<div>
				<TextBox
					onLeave={(v) => setPassword(v)}
					isPassword={true}
				/>
			</div>
			<div>
				Confirm password
			</div>
			<div>
				<TextBox
					onLeave={(v) => setConfirmPassword(v)}
					isPassword={true}
				/>
			</div>
			<div className={styles.button} onClick={submit}>
				Register
			</div>
			{errorMessageJSX}
		</div>
	);
	return (
		<Modal
			content={contentJSX}
			onCancel={props.onCancel}
		/>
	);
}
