import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from "./Modal";
import { TextBox } from "./TextBox";
import { login } from "./network";
import { SHA256, SHA384 } from "sha2";
import styles from './Tea.module.css';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export function LoginModal(props) {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [errorMessage, setErrorMessage] = useState("");

	let submit = () => {
		let saltedPassword = SHA256(password + email).toString("base64");
		login(email, saltedPassword).then((response) => {
			if (response.success) {
				cookies.set("id", response.id);
				cookies.set("password", saltedPassword);
				cookies.set("sessionID", response.sessionID);
				props.onCancel();
			} else {
				setErrorMessage(response.message);
			}
		});
	};

	let errorMessageJSX = []
	if (errorMessage !== "") {
		errorMessageJSX = (
			<div id={styles.register_error}>
				{errorMessage}
			</div>
		);
	}

	let contentJSX = (
		<div id={styles.register}>
			<div>
				Email
			</div>
			<div>
				<TextBox
					onLeave={(v) => setEmail(v)}
					important={true}
				/>
			</div>
			<div>
				Password
			</div>
			<div>
				<TextBox
					onLeave={(v) => setPassword(v)}
					isPassword={true}
				/>
			</div>
			<div className={styles.button} onClick={submit}>
				Log in
			</div>
			{errorMessageJSX}
		</div>
	);
	return (
		<Modal
			content={contentJSX}
			onCancel={props.onCancel}
		/>
	);
}
