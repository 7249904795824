import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextBox } from "./TextBox";
import styles from './Tea.module.css';

export function EditableField(props) {
	const [value, setValue] = useState(props.default);
	const [isEditing, setIsEditing] = useState(false);
	const input = useRef(null);

	let displayJSX = [];
	if (!isEditing) {
		displayJSX = (
			<div className={styles.editable_field} onClick={() => { setIsEditing(true); }}>
				{props.default}
			</div>
		);
	} else {
		let onSubmit = (newValue) => {
			setValue(newValue);
			setIsEditing(false);
			props.onSubmit(newValue);
		}
		displayJSX = (
			<TextBox 
				default={props.default}
				onLeave={onSubmit}
				onPressEnter={onSubmit}
				important={true}
			/>
		)
	}


	return displayJSX;
}
