import React, { useState, useEffect } from 'react';
import styles from './BlackTeaInstructions.module.css';

export function YellowTeaInstructions(props) {
	let readyStatusJSX = [];
	if (props.capacity > 1) {
		readyStatusJSX = (
			<div id={styles.ready_status}>
				{props.readyPlayers.length}/{props.players.length} ready
			</div>
		);
	}

	let buttonJSX = [];
	if (props.capacity <= 1 || props.readyPlayers.length >= props.players.length) {
		buttonJSX = (
			<div id={styles.ready_button} onClick={() => props.newgame()}>
				Start game!
			</div>
		);
	} else {
		buttonJSX = (
			<div id={styles.ready_button} onClick={() => props.ready()}>
				I'm ready!
			</div>
		)
	}

	return (
		<div id={styles.instructions}>
			<div id={styles.text}>
				<p>
					You are about to play <span className={styles.clover_word}>Daisy</span>.
				</p>
				<p>
					This is a game where there is a common pool of tiles, each with a letter on it.
				</p>
				<p>
					Each tile has a point value. Tiles that go unused for some amount of time go up in value.
				</p>
				<p>
					The player with the most points at the end is the winner.
				</p>
			</div>
			<div id={styles.ready}>
				{readyStatusJSX}
				{buttonJSX}
			</div>
		</div>
	);
};