import { createSlice } from '@reduxjs/toolkit';

export const teaSlice = createSlice({
	name: 'tea',
	initialState: {
		name: null,
		room: null
	},
	reducers: {
		setName: (state, action) => {
			state.name = action.payload.name;
		},
		setRoom: (state, action) => {
			state.room = action.payload.room;
		}
	},
});

export const { setName, setRoom } = teaSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectName = state => state.tea.name;
export const selectRoom = state => state.tea.room;

export default teaSlice.reducer;
