import React, { useState, useEffect } from 'react';
import styles from './BlackTeaInstructions.module.css';

export function BlackTeaInstructions(props) {
	let readyStatusJSX = [];
	if (props.capacity > 1) {
		readyStatusJSX = (
			<div id={styles.ready_status}>
				{props.readyPlayers.length}/{props.players.length} ready
			</div>
		);
	}

	let buttonJSX = [];
	if (props.capacity <= 1 || props.readyPlayers.length >= props.players.length) {
		buttonJSX = (
			<div id={styles.ready_button} onClick={() => props.newgame()}>
				Start game!
			</div>
		);
	} else {
		buttonJSX = (
			<div id={styles.ready_button} onClick={() => props.ready()}>
				I'm ready!
			</div>
		)
	}

	return (
		<div id={styles.instructions}>
			<div id={styles.text}>
				<p>
					You are about to play <span className={styles.clover_word}>Clover</span>.
				</p>
				<p>
					This is a game where you are presented with a three-letter sequence.
				</p>
				<p>
					Type as many words that contain that three-letter sequence as you can until time runs out.
				</p>
				<p>
					For example, if the three-letter sequence is "ect", then valid words include "expect", "ectoplasm", and "appendectomy". 
				</p>
				<p>
					The player with the most points at the end of five rounds is the winner. Longer words score more points!
				</p>
			</div>
			<div id={styles.ready}>
				{readyStatusJSX}
				{buttonJSX}
			</div>
		</div>
	);
};