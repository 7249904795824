import Cookies from "universal-cookie";
const queryString = require("querystring");
const cookies = new Cookies();

//const serverURL = "http://localhost:4000";
const serverURL = "https://api.flower-words.com";

let requestsPending = 0;
export function isLoading() {
	//console.log(requestsPending);
	return requestsPending > 0;
}

function makeRequest(method, url, params) {
	requestsPending++;
	//console.log("sending request to " + url);
	//console.log(isLoading());
	if (method === "GET") {
		url = url + "?" + queryString.encode(params);
	}
	return new Promise((resolve, reject) => {
		let xhr = new XMLHttpRequest();
		xhr.addEventListener("load", (e) => {
			if (xhr.status >= 200 && xhr.status < 300) {
				try {
					resolve(JSON.parse(xhr.response));
					requestsPending--;
					//console.log("received response from " + url);
				} catch (err) {
					console.log(err);
					console.log("offending response:");
					console.log(xhr.response)
				}
				
			} else {
				reject({
					status: xhr.status,
					statusText: xhr.statusText
				});
				requestsPending--;
				//console.log("received response from " + url);
			}
		});
		xhr.open(method, url);
		if (method === "POST") {
			params._id = cookies.get("id");
			params.sessionID = cookies.get("sessionID");
			xhr.setRequestHeader("Content-type", "application/json");
		}
		//console.log(JSON.stringify(params));
		xhr.send(JSON.stringify(params));
	});
}

export async function sayHello(id, password) {
	let query = {
		id: id,
		password: password
	}
	return await makeRequest("POST", serverURL + "/hello", query);
}

export async function newRoom(room) {
	let query = {
		name: room.name,
		flavor: room.flavor,
		capacity: room.capacity
	};
	return await makeRequest("POST", serverURL + "/new_room", query);
};

export async function getRooms() {
	return await makeRequest("GET", serverURL + "/rooms");
};

export async function getUsers() {
	return await makeRequest("GET", serverURL + "/users");
};

export async function changeName(name) {
	let query = {
		name: name
	}
	return await makeRequest("POST", serverURL + "/set_name", query);
};

export async function register(email, password) {
	let query = {
		email: email,
		password: password
	}
	return await makeRequest("POST", serverURL + "/register", query);
}

export async function login(email, password) {
	let query = {
		email: email,
		password: password
	}
	return await makeRequest("POST", serverURL + "/login", query);
}

export async function updateRoom(id) {
	let query = {
		id: id
	};
	return await makeRequest("GET", serverURL + "/room", query);
};

export async function joinRoom(roomID) {
	let query = {
		room: roomID
	};
	return await makeRequest("POST", serverURL + "/join_room", query);
};

export async function declareReady(roomID) {
	let query = {
		room: roomID
	};
	return await makeRequest("POST", serverURL + "/declare_ready", query);
}

export async function doGameAction(roomID, action) {
	let query = {
		room: roomID,
		action: action
	};
	return await makeRequest("POST", serverURL + "/game_action", query);
}