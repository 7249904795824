import React, { useEffect, useState } from 'react';
import { YellowTeaInstructions } from "./YellowTeaInstructions";
import { doGameAction, joinRoom, updateRoom, declareReady } from "./network";
import styles from './Tea.module.css';
import Cookies from "universal-cookie";
const cookies = new Cookies();

export function YellowTeaRoom(props) {
	const [room, setRoom] = useState(props.room);
	const [guess, setGuess] = useState("");
	const [guessesPending, setGuessesPending] = useState(0);

	//console.log(room);

	useEffect(() => {
		let lookForUpdates = () => {
			//console.log("asking for updates");
			updateRoom(room._id).then((newRoom) => {
				//console.log("got an update");
				setRoom(newRoom);
				lookForUpdates();
			}).catch((reason) => {
				console.log(reason);
			})
		}
		lookForUpdates();
	}, []);

	useEffect(() => {
		let handleKeyPress = (e) => {
			if (props.allowKeypresses) {
				if (e.code.includes("Key")) {
					setGuess(guess + e.key);
				} else if (e.code === "Enter") { //Enter
					if (guess.length > 0) {
						setGuessesPending(x => x + 1);
						doGameAction(room._id, {
							type: "submitword",
							word: guess
						}).then((newRoom) => {
							setRoom(newRoom);
							setGuessesPending(x => x - 1);
						});
						setGuess("");
					}
				} else if (e.code === "Backspace") { //Backspace
					setGuess(guess.slice(0, guess.length - 1));
				}
			}
		}
		
		window.addEventListener("keydown", handleKeyPress);
		return () => {
			window.removeEventListener("keydown", handleKeyPress);
		}
	}, [guess]);

	let getCurrentGame = (room) => {
		if (room.games.length > 0) {
			return room.games[room.games.length - 1];
		} else {
			return null;
		}
	};

	let activeGame = getCurrentGame(room);

	let gameJSX = null;
	if (activeGame === null) {
		let readyCallback = () => {
			declareReady(room._id).then((room => {}));
			//doGameAction(room._id, {type: "readystart"}).then(room => {});
			//doGameAction(room._id, {type: "newgame"}).then(result => {});
		}
		gameJSX = (
			<YellowTeaInstructions
				capacity={room.capacity}
				players={room.users}
				readyPlayers={room.ready}
				ready={readyCallback}
				newgame={() => { doGameAction(room._id, {type: "newgame"}).then(result => {}) }}
			/>
		);
	} else {
		const playersJSX = [];
		

		//console.log(millisecondsUntilRoundStart);
		/*let countdownJSX = [];
		if (millisecondsUntilRoundStart > 0) {
			let value = "";
			if (millisecondsUntilRoundStart % 1000 === 0) {
				value = millisecondsUntilRoundStart / 1000;
			} else {
				value = "Ready..."
			}
			countdownJSX = (
				<div id={styles.black_tea_countdown}>
					<div>
						{Math.ceil(millisecondsUntilRoundStart / 1000)}
					</div>
				</div>
			);
		}*/
	
		const myGuessJSX = [];
		for (let i in guess) {
			let letter = guess[i].toLowerCase();
			myGuessJSX.push(
				<div key={i} className={styles.black_tea_my_guess_letter}>
					{letter}
				</div>
			);
		}
		if (guess.length === 0) {
			myGuessJSX.push (
				<div key={"-1"}>
					&nbsp;
				</div>
			);
		}
		const guessBarJSX = (
			<div id={styles.black_tea_guessbar}>
				<div id={styles.black_tea_guessbar_guess}>
					&nbsp;{guess}&nbsp;
				</div>
			</div>
		);

		/*let barWidth = (getMillisUntilEnd() * 100 / 20000);
		if (getMillisUntilEnd() < 0) {
			barWidth = 0;
		}

		let countdownBarJSX = [];
		if (barWidth > 0) {
			countdownBarJSX = (
				<div id={styles.black_tea_time_bar} style={{width: Math.min(barWidth, 100) + "%"}}>

				</div>
			);
		}*/

		gameJSX = (
			<div id={styles.yellow_tea_game}>
				<div id={styles.yellow_tea_game_playarea}>
					<div id={styles.yellow_tea_game_poolarea}>
						<div id={styles.yellow_tea_game_pool}>
							{
								activeGame.commonTiles.map((val, index) => 
									<div key={index} className={styles.yellow_tea_game_pool_tile}>
										<div className={styles.yellow_tea_game_pool_tile_letter}>
											{val.letter.toUpperCase()}
										</div>
										<div>
											{val.score}
										</div>
									</div>
								)
							}
						</div>
					</div>
					<div id={styles.yellow_tea_game_players}>
						{Object.keys(activeGame.players).map((player) => {
							return (
								<div key={player} className={styles.yellow_tea_game_players_player}>
									<div>
										{props.users[player].name}
									</div>
									<div>
										{activeGame.players[player].score}
									</div>
								</div>
							);
						})}
					</div>
				</div>
				{guessBarJSX}
			</div>
		);
	}

	let leaveRoom = () => {
		joinRoom(null).then(result => {
			//clearInterval(updater);
			//props.refresh();
		});
	};

	return (
		<div id={styles.yellow_tea_game_room}>
			<div id={styles.black_tea_room_menu}>
				<div id={styles.black_tea_room_name}>
					{room.name}
				</div>
				<div id={styles.room_menu_centerpanel}>
					
				</div>
				<div id={styles.room_menu_rightpanel}>
					<div className={styles.button} onClick={() => doGameAction(room._id, {type: "newgame"}).then(newRoom => { setRoom(newRoom) })} tabIndex="-1">
						New game
					</div>
					<div className={styles.button} onClick={leaveRoom} tabIndex="-1">
						Leave room
					</div>
				</div>
			</div>
			{gameJSX}
		</div>
	);
}
