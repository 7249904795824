import React, { useEffect, useState } from 'react';
import { BlackTeaInstructions } from "./BlackTeaInstructions";
import { doGameAction, joinRoom, updateRoom, declareReady } from "./network";
import styles from "./Tea.module.css";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export function BlackTeaRoom(props) {
	const [room, setRoom] = useState(props.room);
	const [guess, setGuess] = useState("");
	const [millisecondsUntilRoundStart, setMillisecondsUntilRoundStart] = useState(-1);
	const [millisecondsUntilRoundEnd, setMillisecondsUntilRoundEnd] = useState(-1);
	const [millisUntilNextRound, setMillisUntilNextRound] = useState(-1);
	const [guessesPending, setGuessesPending] = useState(0);
	const [lastGuesses, setLastGuesses] = useState([]);
	const [lastGuessIndex, setLastGuessIndex] = useState(0);

	useEffect(() => {
		let handleKeyPress = (e) => {
			if (props.allowKeypresses) {
				if (e.code.includes("Key")) {
					setGuess(guess + e.key);
				} else if (e.code === "Enter") { //Enter
					if (guess.length > 0) {
						setLastGuesses(lastGuesses.concat([guess]));
						setLastGuessIndex(0);
						setGuessesPending(x => x + 1);
						doGameAction(room._id, {
							type: "guess",
							guess: guess
						}).then((newRoom) => {
							setRoom(newRoom);
							setGuessesPending(x => x - 1);
						});
						setGuess("");
					}
				} else if (e.code === "Backspace") { //Backspace
					setGuess(guess.slice(0, guess.length - 1));
				} else if (e.code === "ArrowUp") {
					if (lastGuesses.length > 0 && lastGuessIndex < lastGuesses.length) {
						setGuess(lastGuesses[lastGuesses.length - lastGuessIndex - 1]);
						setLastGuessIndex(lastGuessIndex + 1);
					}
				} else if (e.code === "Space") {
					if (lastGuesses.length > 0) {
						if (lastGuessIndex < lastGuesses.length) {
							setGuess(lastGuesses[lastGuesses.length - lastGuessIndex - 1]);
							setLastGuessIndex(lastGuessIndex + 1);
						} else {
							setGuess(lastGuesses[lastGuesses.length - 1]);
							setLastGuessIndex(1);
						}
					}
				} else if (e.code === "ArrowDown") {
					if (lastGuessIndex > 1) {
						setGuess(lastGuesses[lastGuesses.length - lastGuessIndex + 1]);
						setLastGuessIndex(lastGuessIndex - 1);
					} else if (lastGuessIndex === 1) {
						setGuess("");
						setLastGuessIndex(lastGuessIndex - 1);
					}
				}
			}
		};
		
		let handleFocus = (e) => {
			updateRoom(room._id).then((newRoom) => {
				setRoom(newRoom);
			});
		};

		window.addEventListener("keydown", handleKeyPress);
		window.addEventListener("focus", handleFocus)
		return () => {
			window.removeEventListener("keydown", handleKeyPress);
			window.removeEventListener("focus", handleFocus);
		}
	}, [guess, lastGuessIndex, lastGuesses.length]);

	let getCurrentGame = (room) => {
		if (room.games.length > 0) {
			return room.games[room.games.length - 1];
		} else {
			return null;
		}
	};

	let getCurrentRound = (game) => {
		if (game.rounds.length > 0) {
			return game.rounds[game.rounds.length - 1];
		} else {
			return null;
		}
	};

	useEffect(() => {
		setLastGuesses([]);
		setLastGuessIndex(0);
		setGuess("");
	}, [room.games.length, getCurrentGame(room) === null ? 0 : getCurrentGame(room).rounds.length]);

	useEffect(() => {
		let okay = {status: true};
		let getUpdates = () => {
			updateRoom(room._id).then((newRoom) => {
				if (okay.status) {
					setRoom(newRoom);
					if (newRoom !== null) {
						let curGame = getCurrentGame(newRoom);
						if (curGame !== null) {
							let curRound = getCurrentRound(curGame);
							if (curRound !== null) {
								setMillisecondsUntilRoundStart(new Date(curRound.startTime).getTime() - Date.now());
								setMillisecondsUntilRoundEnd(new Date(curRound.endTime).getTime() - Date.now());
							}
						}
					}
					getUpdates();
				}
			});
		};
		getUpdates();
		return () => {
			okay.status = false;
		};
	}, []);

	/*let activeGame = null;
	if (room.games.length > 0) {
		activeGame = room.games[room.games.length - 1];
	} */
	let activeGame = getCurrentGame(room);

	let currentRound = null;
	if (activeGame !== null && activeGame.rounds.length > 0) {
		currentRound = activeGame.rounds[activeGame.rounds.length - 1];
	}

	let nowTimeMillis = Date.now();
	let startTimeMillis = -1;
	let endTimeMillis = -1;
	
	if (currentRound !== null) {
		startTimeMillis = new Date(currentRound.startTime).getTime();
		endTimeMillis = new Date(currentRound.endTime).getTime();
	}
	let getMillisUntilStart = () => {
		if (currentRound !== null) {
			return startTimeMillis - nowTimeMillis;
		} else {
			return -1;
		}
	}
	let getMillisUntilEnd = () => {
		if (currentRound !== null) {
			return endTimeMillis - nowTimeMillis;
			//return new Date(currentRound.endTime).getTime() - Date.now();
		} else {
			return -1;
		}
	}

	useEffect(() => {
		let interval = setInterval(() => {
			setMillisecondsUntilRoundStart(x => x - 100);
			setMillisecondsUntilRoundEnd(x => x - 100);
		}, 100);
		
		return () => {
			clearInterval(interval);
		}
	}, []);

	useEffect(() => {
		let timer = null;
		let counter = [];
		if (currentRound !== null && getMillisUntilEnd() < 0) {
			//console.log("getting ready for next round");
			setMillisUntilNextRound(3000);
			for (let n = 1000; n < 3000; n += 1000) {
				counter.push(setTimeout(() => {
					setMillisUntilNextRound(3000 - n);
				}, n));
			}
			timer = setTimeout(() => {
				//console.log("sending ready message");
				doGameAction(room._id, {
					type: "ready"
				}).then(newRoom => {
					//console.log(newRoom);
					setRoom(newRoom);
				});
			}, 3000);
		} else {
			;
		}
		return () => {
			//console.log("cleanup");
			clearTimeout(timer);
			for (let c in counter) {
				clearTimeout(c);
			}
		};
	}, [currentRound === null || getMillisUntilEnd() > 0]);

	useEffect(() => {
		if (getMillisUntilEnd() > 0) {
			props.setSpinning(true);
		} else {
			props.setSpinning(false);
		}
		return () => {
			props.setSpinning(false);
		}
	}, [currentRound === null, getMillisUntilEnd() > 0]);

	let gameJSX = null;
	if (activeGame === null) {
		let readyCallback = () => {
			declareReady(room._id).then((room => {}));
			//doGameAction(room._id, {type: "readystart"}).then(room => {});
			//doGameAction(room._id, {type: "newgame"}).then(result => {});
		}
		gameJSX = (
			<BlackTeaInstructions
				capacity={room.capacity}
				players={room.users}
				readyPlayers={room.ready}
				ready={readyCallback}
				newgame={() => { doGameAction(room._id, {type: "newgame"}).then(result => {}) }}
			/>
		);
	} else {const playersJSX = [];
		if (currentRound !== null) {

			//console.log(millisecondsUntilRoundStart);
			let countdownJSX = [];
			if (millisecondsUntilRoundStart > 0) {
				let value = "";
				if (millisecondsUntilRoundStart % 1000 === 0) {
					value = millisecondsUntilRoundStart / 1000;
				} else {
					value = "Ready..."
				}
				countdownJSX = (
					<div id={styles.black_tea_countdown}>
						<div>
							{Math.ceil(millisecondsUntilRoundStart / 1000)}
						</div>
					</div>
				);
			}

			for (let playerID in activeGame.players) {
				let playerName = "";
				if (playerID in props.users) {
					playerName = props.users[playerID].name;
				}
				let guessesJSX = [];
				if (playerID in currentRound.guesses) {
					for (let index in currentRound.guesses[playerID]) {
						let guessedWord = currentRound.guesses[playerID][index].word;
						let score = currentRound.guesses[playerID][index].score;
						//let statusClass = "black_tea_guess_" + currentRound.guesses[playerID][index].result;

						let wordJSX = [];
						if (currentRound.guesses[playerID][index].result === "valid") {
							let beforePart = guessedWord.substring(0, guessedWord.indexOf(currentRound.prompt));
							let promptPart = currentRound.prompt;
							let afterPart = guessedWord.substring(guessedWord.indexOf(currentRound.prompt) + currentRound.prompt.length, guessedWord.length);
							/*console.log("giros".indexOf("gir"));
							console.log(`asd ${currentRound.prompt} | ${guessedWord} | ${x}`);
							console.log(`before: ${beforePart} | prompt: ${promptPart} | afterPart: ${afterPart}`);*/
							wordJSX = (
								<div className={styles.black_tea_guess_word_split}>
									<div className={styles.black_tea_guess_word_split_before}>
										{beforePart}
									</div>
									<div className={styles.black_tea_guess_word_split_prompt}>
										{promptPart}
									</div>
									<div className={styles.black_tea_guess_word_split_after}>
										{afterPart}
									</div>
								</div>
							);
						} else {
							wordJSX = (
								<div className={styles.black_tea_guess_word_unsplit}>
									{guessedWord}
								</div>
							);
						}

						guessesJSX.push(
							<div key={index} className={styles.black_tea_guess}>
								<div className={styles.black_tea_guess_icon}>
									☆
								</div>
								<div className={styles.black_tea_guess_word}>
									{wordJSX}
								</div>
								<div className={styles.black_tea_guess_score}>
									{score}
								</div>
							</div>
						);
					}
				}
				if (playerID === cookies.get("id")) {	
					for (let i = 0; i < guessesPending; i++) {
						guessesJSX.push(
							<div key={"pending" + i} className={styles.black_tea_guess_pending}>
								...
							</div>
						);
					}
				}
				
				playersJSX.push(
					<div className={styles.black_tea_player} key={playerID}>
						<div className={styles.black_tea_guesses}>
							<div className={styles.black_tea_player_header}>
								&nbsp;
							</div>
							{guessesJSX}
						</div>
						<div>
							<div className={styles.black_tea_score}>
								{activeGame.players[playerID].score}
							</div>
							<div className={styles.black_tea_playername}>
								{playerName}
							</div>
						</div>
						{countdownJSX}
					</div>
				);
			}
		}
		const myGuessJSX = [];
		for (let i in guess) {
			let letter = guess[i].toLowerCase();
			myGuessJSX.push(
				<div key={i} className={styles.black_tea_my_guess_letter}>
					{letter}
				</div>
			);
		}
		if (guess.length === 0) {
			myGuessJSX.push (
				<div key={"-1"}>
					&nbsp;
				</div>
			);
		}
		const guessBarJSX = (
			<div id={styles.black_tea_guessbar}>
				<div id={styles.black_tea_guessbar_guess}>
					&nbsp;{guess}&nbsp;
				</div>
			</div>
		);


		let message = "I hope you're having fun!";
		if (getMillisUntilStart() > 0) {
			message = `Round starts in ${Math.ceil(getMillisUntilStart() / 1000)}`;
		} else if (getMillisUntilEnd() > 0) {
			message = `Round ends in ${Math.ceil(getMillisUntilEnd() / 1000)}`;
		} else if (millisUntilNextRound > 0) {
			if (activeGame.maxRounds > activeGame.rounds.length) {
				message = `Next round starting in ${Math.ceil(millisUntilNextRound / 1000)}`;
			} else {
				let winners = [];
				let bestScore = 0;
				for (let playerID in activeGame.players) {
					let playerData = activeGame.players[playerID];
					if (playerData.score == bestScore) {
						winners.push(playerID);
					} else if (playerData.score > bestScore) {
						winners = [playerID];
						bestScore = playerData.score;
					}
				}
				//console.log(props.users);
				//console.log(winners);
				message = `Game over! ${props.users[winners[0]].name} wins!`;
			}
		}

		let barWidth = (getMillisUntilEnd() * 100 / 20000);
		if (getMillisUntilEnd() < 0) {
			barWidth = 0;
		}

		let countdownBarJSX = [];
		if (barWidth > 0) {
			countdownBarJSX = (
				<div id={styles.black_tea_time_bar} style={{width: Math.min(barWidth, 100) + "%"}}>

				</div>
			);
		}

		gameJSX = (
			<div id={styles.black_tea_game}>
				<div id={styles.black_tea_time}>
					{countdownBarJSX}
				</div>
				<div id={styles.black_tea_gameinfo}>
					<div id={styles.black_tea_gameinfo_round}>
						Round {activeGame.rounds.length} of 5
					</div>
					<div>
						{message}
					</div>
					<div id={styles.black_tea_gameinfo_prompt}>
						{millisecondsUntilRoundStart > 0 ? "---" : currentRound.prompt}
					</div>
				</div>
				<div id={styles.black_tea_players}>
					{playersJSX}
				</div>
				{guessBarJSX}
			</div>
		);
	}

	let leaveRoom = () => {
		joinRoom(null).then(result => {
			//clearInterval(updater);
			//props.refresh();
		});
	};

	return (
		<div id={styles.black_tea_room} className={styles.black_tea_game_room}>
			<div id={styles.black_tea_room_menu}>
				<div id={styles.black_tea_room_name}>
					{room.name}
				</div>
				<div id={styles.room_menu_centerpanel}>
					
				</div>
				<div id={styles.room_menu_rightpanel}>
					<div className={styles.button} onClick={() => doGameAction(room._id, {type: "newgame"}).then(newRoom => { setRoom(newRoom) })} tabIndex="-1">
						New game
					</div>
					<div className={styles.button} onClick={leaveRoom} tabIndex="-1">
						Leave room
					</div>
				</div>
			</div>
			{gameJSX}
		</div>
	);
}
