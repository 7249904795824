import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	setName,
	setRoom,
	selectName,
	selectRoom
} from './teaSlice';
import styles from './Tea.module.css';
import { isLoading, sayHello, newRoom, getRooms, getUsers, changeName, joinRoom } from "./network.js";
import { RegisterModal } from "./RegisterModal";
import { LoginModal } from "./LoginModal";
//import { TextBox } from "./TextBox"
import { CreateRoom } from "./CreateRoom";
import { BlackTeaRoom } from "./BlackTeaRoom";
import { YellowTeaRoom } from "./YellowTeaRoom";
import { Welcome } from "./Welcome";
import { GameSelect } from "./GameSelect";
import { EditableField } from "./EditableField";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export function Tea() {
	//const count = useSelector();
	const dispatch = useDispatch();
	const [rooms, setRooms] = useState({});
	const [users, setUsers] = useState({});
	const [modal, setModal] = useState("");
	const [currentRoom, setCurrentRoom] = useState("");
	const [currentScreen, setCurrentScreen] = useState("welcome");
	const [keyboardLock, setKeyboardLock] = useState("");
	const [spinning, setSpinning] = useState(false);

	//let userID = cookies.get("id");
	
	let refresh = () => {
		getRooms().then((updatedRooms) => {
			setRooms(updatedRooms);
		}, (err) => {
			console.log(err);
		});
		getUsers().then((updatedUsers) => {
			setUsers(updatedUsers);
		}, (err) => {
			console.log(err);
		});
	};

	useEffect(() => {
		sayHello(cookies.get("id"), cookies.get("password")).then((response) => {
			//console.log(response);
			cookies.set("id", response.id);
			cookies.set("sessionID", response.sessionID);
			setRooms(response.rooms);
			setUsers(response.users);
		});
		
		let keepGoing = true;
		let refreshRooms = () => {
			getRooms().then((updatedRooms) => {
				if (keepGoing) {
					setRooms(updatedRooms);
					refreshRooms();
				}
			}, (err) => {
				console.log(err);
			});
		}
		refreshRooms();
		getUsers().then((updatedUsers) => {
			if (keepGoing) {
				setUsers(updatedUsers);
				//getUsers();
			}
		}, (err) => {
			console.log(err);
		});
		
		return () => {
			keepGoing = false;
		};
		/*refresh();
		setInterval(() => {
			refresh();
		}, 5000);*/
	}, []);

	let userRoom = null;

	const roomsJSX = [];
	for (let roomID in rooms) {
		let room = rooms[roomID];
		if (room.users.includes(cookies.get("id"))) {
			userRoom = room;
		}
		roomsJSX.push(
			<div key={room._id} className={styles.rooms_room} onClick={(e) => {joinRoom(room._id).then(result => {})}}>
				<div>
					{room.name}
				</div>
				<div>
					{room.users.length}/{room.capacity} users
				</div>
			</div>
		);
	}

	let mainJSX = [];
	if (currentScreen === "welcome") {
		mainJSX = (
			<GameSelect
				rooms={rooms}
				refresh={refresh}
			/>
		)
		/*mainJSX = (
			<Welcome
				browse={() => setCurrentScreen("browse")}
				rooms={rooms}
				createRoom={(roomName, flavor, capacity) => {
						newRoom({name: roomName, flavor: flavor, capacity: capacity}).then((createdRoom) => {
							refresh();
						});
					}
				}
				joinRoom={(roomID) => {
						joinRoom(roomID).then(() => {
							refresh();
						})
					}
				}
			/>
		);*/
	} else if (currentScreen === "browse") {
		mainJSX = (
			<div id={styles.main}>
				<div id={styles.rooms}>
					{roomsJSX}
				</div>
			</div>
		);
	}

	if (userRoom === null) {
		
	} else {
		if (userRoom.flavor === "black") {
			mainJSX = (
				<BlackTeaRoom
					room={userRoom}
					users={users}
					refresh={refresh}
					allowKeypresses={keyboardLock === ""}
					setSpinning={(b) => setSpinning(b)}
				/>
			);
		} else if (userRoom.flavor === "yellow") {
			mainJSX = (
				<YellowTeaRoom
					room={userRoom}
					users={users}
					refresh={refresh}
					allowKeypresses={keyboardLock === ""}
					setSpinning={(b) => setSpinning(b)}
				/>
			);
		} else {
			mainJSX = (
				<div onClick={() => joinRoom(null).then((response) => {})}>
					Leave room
				</div>
			)
		}
	}

	let makeRoomJSX = (
		<div id={styles.make_room}>
			<CreateRoom
				onSubmit={(room) => {
					newRoom(room).then(result => {
						let newRooms = {};
						Object.assign(newRooms, rooms);
						newRooms[result._id] = result;
						setRooms(newRooms);
					})
				}}
			/>
		</div>
	);
	//console.log(rooms);
	//console.log(users);

	let logout = () => {
		cookies.remove("id");
		cookies.remove("password");
		cookies.remove("sessionID");
		sayHello().then((response) => {
			cookies.set("id", response.id);
			cookies.set("sessionID", response.sessionID);
			setRooms(response.rooms);
			setUsers(response.users);
		});
	};

	let loginStatusJSX = [];
	let loginInfoJSX = [];
	if (cookies.get("id") in users) {
		if (users[cookies.get("id")].status === "fleeting") {
			loginStatusJSX = (
				<div id={styles.title_login_status}>
					<div onClick={() => {setModal("register")}}>
						Register
					</div>
					&nbsp;|&nbsp;
					<div onClick={() => {setModal("login")}}>
						Log in
					</div>
				</div>
			);
		} else if (users[cookies.get("id")].status === "unverified") {
			loginStatusJSX = (
				<div id={styles.title_login_status}>
					<div>
						Verify your account
						</div>
					&nbsp;|&nbsp;
					<div onClick={logout}>
						Log out
					</div>
				</div>
			);
		} else {
			loginStatusJSX = (
				<div id={styles.title_login_status}>
					Logged in
				</div>
			);
		}
		loginInfoJSX = (
			<div id={styles.title_username}>
				<EditableField
					default={users[cookies.get("id")].name}
					onSubmit={(v) => {changeName(v).then(() => {refresh()})}}
				/>
			</div>
		)
		
	} else {
		loginInfoJSX = (
			<div id={styles.title_username}>
				Unable to connect to server
			</div>
		)
	}

	let modalJSX = <div></div>;
	if (modal === "register") {
		modalJSX = (
			<RegisterModal
				onCancel={() => { refresh(); setModal(""); }}
			/>
		);
	} else if (modal === "login") {
		modalJSX = (
			<LoginModal
				onCancel={() => { refresh(); setModal(""); }}
			/>
		);
	}

	let daisyIcon = (
		<img 
			id={styles.title_name_icon} src="./img/daisy.png" alt=""
			
		/>
	);
	
	if (spinning) {
		daisyIcon = (
			<img 
				id={styles.title_name_icon} src="./img/daisy.gif" alt=""
				
			/>
		);
	}

	return (
		<div id={styles.tea}>
			{modalJSX}
			<div id={styles.top_bar}>
				<div id={styles.title_name} onClick={() => joinRoom(null).then(() => setCurrentScreen("welcome"))}
					onMouseEnter={() => setSpinning(true)}
					onMouseLeave={() => setSpinning(false)}>
					{daisyIcon}
					<div id={styles.title_name_text}>some word games</div>
				</div>
				<div></div>
				<div id={styles.title_profile}>
					{loginStatusJSX}
					{loginInfoJSX}
				</div>
			</div>
			{mainJSX}
		</div>
	);
}
